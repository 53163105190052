import React, { useState, useEffect } from 'react';
import { Button, Container, Col, Row, Modal } from 'react-bootstrap';

import api from '../../components/api';
import NavBar from '../../components/NavBar/NavBar';
import { User } from '../../api';

import './DashboardPage.css';

function DashboardPage() {

  const [users, setUsers] = useState<User[]>([]);
  const [showUserCreate, setShowUserCreate] = useState<boolean>(false);
  const [showUserInfo, setShowUserInfo] = useState<boolean>(false);
  const [newUserEmail, setNewUserEmail] = useState<string>();
  const [newUserPassword, setNewUserPassword] = useState<string>();
  const [newUserConfirmPassword, setNewUserConfirmPassword] = useState<string>();
  const [selectedUser, setSelectedUser] = useState<User>();

  useEffect(() => {
    api.users.usersUsersGet().then(data => {
      setUsers(data.data.contents);
    })
  }, [])

  const handleCancelModal = () => {
    setShowUserCreate(false);
  }

  const HandleUserCreate = () => {
    if (newUserPassword != newUserConfirmPassword)
      alert("wrong password");
    if (newUserEmail && newUserPassword)
      api.users.postUserUserPost({
        user_email: newUserEmail,
        password: newUserPassword,
      }).then(data => {
        console.log(data);
      })
  }

  const handleUserInfo = (userId: string) => {
    setShowUserInfo(true);
    api.users.getUsersUserIdUsersUserIdGet(userId).then(data => {
      setSelectedUser(data.data);
    });
  }

  return (
    <div className="page-container">
      <NavBar />
      <Container>
        <Row>
          <Col className="list-container">
            <div className="user-list">
              <nav className="list-header">
                <div className="header-title">Guests</div>
                <div className="add-btn" onClick={() => setShowUserCreate(true)} >
                  <i className="fa fa-plus"></i>
                </div>
              </nav>
              {users?.map((user) => (
                <div className='list-item' key={user.user_id}>
                  <div className="list-name">{user.user_email}</div>
                  <Button
                    variant="dangerous"
                    className="remove-guest-btn"
                    onClick={() => handleUserInfo(user.user_id)}>
                    <i className="fa fa-eye"></i>
                  </Button>
                </div>
              ))}
            </div>
          </Col>
        </Row>
      </Container>
      <Modal show={showUserCreate} onHide={handleCancelModal}>
        <Modal.Header closeButton>
          <Modal.Title>Add New User</Modal.Title>
        </Modal.Header>
        <input
          type="text"
          placeholder="Enter user email"
          value={newUserEmail}
          onChange={(e) => setNewUserEmail(e.target.value)}
        />
        <input
          type="password"
          placeholder="Enter password"
          value={newUserPassword}
          onChange={(e) => setNewUserPassword(e.target.value)}
        />
        <input
          type="password"
          placeholder="Confirm password"
          value={newUserConfirmPassword}
          onChange={(e) => setNewUserConfirmPassword(e.target.value)}
        />
        <div className="modal-buttons">
          <button className="save-btn" onClick={HandleUserCreate}>
            Save
          </button>
          <button className="cancel-btn" onClick={handleCancelModal}>
            Cancel
          </button>
        </div>
      </Modal>
      <Modal show={showUserInfo} onHide={() => setShowUserInfo(false)}>
        <Modal.Header closeButton>
          <Modal.Title>User Info</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p><strong>Email:</strong> {selectedUser?.user_email}</p>
          <p><strong>First Name:</strong> {selectedUser?.first_name}</p>
          <p><strong>Last Name:</strong> {selectedUser?.last_name}</p>
          <p><strong>role:</strong> {selectedUser?.role}</p>
          <p><strong>Wedding Date:</strong> {(selectedUser as any)?.weddings[0]?.wedding_date.substring(0, 10)}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowUserInfo(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

    </div>
  );
};

export default DashboardPage;
