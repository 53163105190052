import React from 'react';

import NavBar from '../../components/NavBar/NavBar'

import './HomePage.css';

function HomePage() {
  return (
    <div className="home-page">
      <NavBar/>
      <div className="home-content">
        <h1 className="home-title">Plan your dream wedding with ease</h1>
        <p className="home-description">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed at lacus vel ligula pretium posuere vel in sapien. Fusce porta, risus nec mollis posuere, elit mauris bibendum elit, eu suscipit mi nisi id magna. Donec varius eget velit eu pharetra.
        </p>
        <button className="home-button">Get started</button>
      </div>
    </div>
  );
}

export default HomePage;
