import React from 'react';
import { Accordion, Card } from 'react-bootstrap';
import { useDrop } from 'react-dnd';

import { Table } from '../../api';
import DraggableGuest, { DraggableGuestItem } from '../../components/DraggableGuest/DraggableGuest';
import './DroppableTable.css';

interface Props {
  table: Table;
  handleDropGuest: (guestID: string, tableID: string) => void;
  handleRemoveGuest: (guestID: string, tableID: string) => void;
  onContextMenu?: (e: any, table: Table) => void;
}

function DroppableTable({ table, handleDropGuest, handleRemoveGuest, onContextMenu }: Props) {
  const [, dropRef] = useDrop({
    accept: 'guest',
    drop: (item: DraggableGuestItem, monitor) => {
      handleDropGuest(item.guest.guest_id, table.table_id)
    },
  });

  const totalGuests = (guests: any[]) => {
    let sum = 0;
    guests.map(guest => {
      sum += guest.guest_count || 0;
      return guest;
    })
    return sum;
  }

  return (
    <div ref={dropRef}
      key={table.table_id}>
      <Accordion>
        <Accordion.Item eventKey={`${table.table_id}`}>
          <Accordion.Header onContextMenu={(e) => onContextMenu ? onContextMenu(e, table) : undefined}>
            <div className="list-name">{table.table_name}</div>
            <div className="list-count">{totalGuests(table.guests || [])}/{table.table_guest_count}</div>
          </Accordion.Header>
          <Accordion.Collapse eventKey={`${table.table_id}`}>
            <Card.Body>
              {table.guests?.map((guest: any) => (
                <DraggableGuest
                  key={guest.guest_id}
                  guest={guest}
                  tableID={table.table_id}
                  handleRemoveGuest={handleRemoveGuest} />
              ))}
              {table.guests?.length === 0 && <div className='list-item'>No guests at this table</div>}
            </Card.Body>
          </Accordion.Collapse>
        </Accordion.Item>
      </Accordion>
    </div>
  );
}

export default DroppableTable;
