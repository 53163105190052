import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import NavBar from '../../components/NavBar/NavBar'
import DateSelector from '../../components/DateSelector/DateSelector'

import api from '../../components/api'
import { User, Wedding } from '../../api';

import './WeddingDashboardPage.css';

function WeddingDashboardPage() {

  const navigate = useNavigate();

  const [weddingDate, setWeddingDate] = useState("");
  const [wedding, setWedding] = useState<Wedding>();
  const [currentUser, setCureentUser] = useState<User>();

  useEffect(() => {
    api.weddings.getWeddingsWeddingsGet().then(data => {
      setWedding(data.data.contents[0]);
    });
    api.users.userUserGet().then(data => {
      setCureentUser(data.data);
    });
  }, []);

  // Wedding create functions
  const handleWeddingDateChange = (newValue: string) => {
    setWeddingDate(newValue);
  };

  const handleCreateWedding = () => {
    console.log(weddingDate)
    api.weddings.postWeddingsWeddingsPost({
      wedding_date: weddingDate,
    }).then(data => {
      setWedding(data.data);
    });
  };

  const CreateButton = () => {
    return (
      <div className="create-button" onClick={handleCreateWedding}>
        Create
      </div>
    );
  };

  return (
    <div className="page-container">
      <NavBar />
      {wedding ?
        <div className='dashboard-container'>
           <div className="header">
        <div className="username">Hi, {currentUser?.first_name}</div>
        <div className="wedding-date">
          <span>{`Your Wedding Date is ${weddingDate.substring(0, 10)}`}</span>
        </div>
      </div>
          <div className="grid">
            <div className="card" onClick={() => {
              navigate(`/weddings/${wedding.wedding_id}/table-list`);
            }}>
              <div className="icon"><i className="fa fa-chair"></i></div>
              <div className="text">Plan my tables</div>
            </div>
            <div className="card">
              <div className="icon"><i className="fa fa-hourglass"></i></div>
              <div className="text">Coming soon...</div>
            </div>
          </div>
        </div>
        :
        <div className="create-wedding-container">
          <h1 className="dashboard-title">Create a Wedding</h1>
          <DateSelector onValueChange={handleWeddingDateChange} />
          <CreateButton />
        </div>
      }
    </div>
  );
};

export default WeddingDashboardPage;
