import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import HomePage from './pages/Home/HomePage';
import LoginPage from './pages/Login/LoginPage';
import WeddingDashboardPage from './pages/Weddings/WeddingDashboardPage';
import WeddingTableListPage from './pages/Weddings/WeddingTableListPage';
import WeddingTableLayoutPage from './pages/Weddings/WeddingTableLayoutPage';
import WeddingTablePublishedPage from './pages/Weddings/WeddingTablePublishedPage';
import DashboardPage from './pages/Dashboard/DashboardPage';

function AppRoutes() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage/>} />
        <Route path="/login" element={<LoginPage/>} />
        <Route path="/weddings" element={<WeddingDashboardPage/>} />
        <Route path="/weddings/:weddingId/table-list" element={<WeddingTableListPage/>} />
        <Route path="/weddings/:weddingId/table-layout" element={<WeddingTableLayoutPage/>} />
        <Route path="/weddings/:weddingId/published" element={<WeddingTablePublishedPage/>} />
        <Route path="/dashboard" element={<DashboardPage/>} />
      </Routes>
    </Router>
  );
}

export default AppRoutes;

