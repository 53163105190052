import React from 'react';
import { Button } from 'react-bootstrap';
import { useDrag } from 'react-dnd';

import { Guest } from '../../api';

import './DraggableGuest.css';

export interface DraggableGuestItem {
  guest: Guest;
}

interface Props {
  guest: Guest;
  tableID?: string;
  onContextMenu?: (e: any, guest: Guest) => void;
  handleRemoveGuest?: (guestID: string, tableID: string) => void;
}

function DraggableGuest({ guest, tableID, handleRemoveGuest, onContextMenu }: Props) {
  const [{ isDragging }, dragRef] = useDrag(() => ({
    type: 'guest',
    item: { guest },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  }));

  return (
    <div
      ref={dragRef}
      key={guest.guest_id}
      className="list-item"
      style={{ opacity: isDragging ? 0.5 : 1 }}
      onContextMenu={(e) => onContextMenu?onContextMenu(e, guest):undefined}
    >
      <div className="list-name">{guest.guest_name}</div>
      <div className="list-count">{guest.guest_count}P.</div>
      {handleRemoveGuest &&
        <Button
          variant="dangerous"
          className="remove-guest-btn"
          onClick={() => handleRemoveGuest(guest.guest_id, tableID || "")}>
          <i className="fa fa-times"></i>
        </Button>
      }
    </div>
  );
}

export default DraggableGuest;
