import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Navbar, Container } from 'react-bootstrap';


import { config } from '../../components/api';
import { logout } from '../../components/utils'

import './NavBar.css';

function NavBar() {
  const navigate = useNavigate();

  return (
    <Navbar bg="light" expand="lg">
      <Container>
        <Navbar.Brand className="navbar-title">Wedding Planner</Navbar.Brand>
        <div className="navbar-buttons">
          {
            config.accessToken ?
              <button className="navbar-button" onClick={() => {
                logout();
                navigate("/");
              }}>Log out</button> :
              <button className="navbar-button" onClick={() => {
                navigate("/login")
              }}>Log in</button>
          }
        </div>
      </Container>
    </Navbar>
  );
}

export default NavBar;
