import React, { useEffect, useState } from 'react';

import './DateSelector.css';

interface Props {
  onValueChange: (weddingDate: string) => void;
}

function DateSelector({ onValueChange }: Props) {
  useEffect(() => {
    handleDateChange();
  });

  const [year, setYear] = useState(new Date().getFullYear());
  const [month, setMonth] = useState(new Date().getMonth() + 1);
  const [day, setDay] = useState(new Date().getDate());

  const handleDateChange = () => {
    const dateObj = new Date(year, month - 1, day);
    onValueChange(dateObj.toISOString());
  };

  const handleYearChange = (amount: number) => {
    setYear(year + amount);
    handleDateChange();
  };

  const handleMonthChange = (amount: number) => {
    const newMonth = month + amount;
    if (newMonth < 1) {
      setYear(year - 1);
      setMonth(12);
    } else if (newMonth > 12) {
      setYear(year + 1);
      setMonth(1);
    } else {
      setMonth(newMonth);
    }
    handleDateChange();
  };

  const handleDayChange = (amount: number) => {
    const newDay = day + amount;
    const maxDays = new Date(year, month, 0).getDate();
    if (newDay < 1) {
      handleMonthChange(-1);
      setDay(maxDays);
    } else if (newDay > maxDays) {
      handleMonthChange(1);
      setDay(1);
    } else {
      setDay(newDay);
    }
    handleDateChange();
  };
  return (
    <div className="date-selector">
      <div className="selector-container">
        <div className="selector-arrow up-arrow" onClick={() => handleYearChange(1)}>&#9650;</div>
        <input type="text" className="selector-year" value={year} onChange={(e) => setYear(parseInt(e.target.value))} />
        <div className="selector-arrow down-arrow" onClick={() => handleYearChange(-1)}>&#9660;</div>
      </div>
      <div className="selector-container">
        <div className="selector-arrow up-arrow" onClick={() => handleMonthChange(1)}>&#9650;</div>
        <input type="text" className="selector-month" value={month} onChange={(e) => setMonth(parseInt(e.target.value))} />
        <div className="selector-arrow down-arrow" onClick={() => handleMonthChange(-1)}>&#9660;</div>
      </div>
      <div className="selector-container">
        <div className="selector-arrow up-arrow" onClick={() => handleDayChange(1)}>&#9650;</div>
        <input type="text" className="selector-day" value={day} onChange={(e) => setDay(parseInt(e.target.value))} />
        <div className="selector-arrow down-arrow" onClick={() => handleDayChange(-1)}>&#9660;</div>
      </div>
    </div>
  );
}

export default DateSelector;
