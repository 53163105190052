import { config } from './api';

export const logout = () => {
  window.localStorage.removeItem("access_token");
  config.accessToken = undefined;
}

export const login = (accessToken: string) => {
  window.localStorage.setItem("access_token", accessToken);
  config.accessToken = accessToken;
}