import React from 'react';

import './ContextMenu.css'

interface ContextMenuProps {
  x: number;
  y: number;
  items: { label: string; onClick: () => void }[];
}

function ContextMenu({ x, y, items }: ContextMenuProps ) {
  return (
    <div
      className="context-menu"
      style={{
        position: "absolute",
        left: x,
        top: y,
      }}
    >
      <ul className="context-menu-list">
        {items.map((item, index) => (
          <li key={index} className="context-menu-item" onClick={item.onClick}>
            {item.label}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ContextMenu
