import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Modal, Navbar, Row } from "react-bootstrap";
import { useParams, useNavigate } from 'react-router-dom';
import { DndProvider, } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useRef } from 'react';
import { QRCodeCanvas } from "qrcode.react";

import api from '../../components/api';
import { Guest, Table } from '../../api';
import NavBar from '../../components/NavBar/NavBar';
import DraggableGuest from '../../components/DraggableGuest/DraggableGuest';
import DroppableTable from '../../components/DroppableTable/DroppableTable';
import ContextMenu from '../../components/ContextMenu/ContextMenu';

import './WeddingTableListPage.css';

const tableStyleMap = {
  1: "https://www.ottozhang.com/static/img/icons/round_table.png",
  2: "https://www.ottozhang.com/static/img/icons/long_table.png",
  3: "https://www.ottozhang.com/static/img/icons/stage.png"
}

function useOnClickOutside(ref: React.RefObject<HTMLElement>, handler: () => void) {
  useEffect(() => {
    const listener = (event: MouseEvent) => {
      if (!ref.current || ref.current.contains(event.target as Node)) {
        return;
      }
      handler();
    };

    document.addEventListener('mousedown', listener);
    return () => {
      document.removeEventListener('mousedown', listener);
    };
  }, [ref, handler]);
}

function WeddingTableListPage() {
  const navigate = useNavigate();
  const { weddingId } = useParams<{ weddingId: string }>();

  const [tables, setTables] = useState<Table[]>([]);
  const [guests, setGuests] = useState<Guest[]>([]);
  const [showGuestCreate, setShowGuestCreate] = useState<boolean>(false);
  const [newGuestName, setNewGuestName] = useState<string>();
  const [newGuestCount, setNewGuestCount] = useState<number>();
  const [modifyGuestID, setModifyGuestID] = useState<string>();
  const [showTableCreate, setShowTableCreate] = useState<boolean>(false);
  const [newTableName, setNewTableName] = useState<string>();
  const [newTableCount, setNewTableCount] = useState<number>();
  const [newTableStyle, setNewTableStyle] = useState<string>();
  const [selectedTableStyle, setSelectedTableStyle] = useState<number>(1);
  const [modifyTableID, setModifyTableID] = useState<string>();
  const [contextMenu, setContextMenu] = useState<{
    x: number;
    y: number;
    items: { label: string; onClick: () => void }[];
  }>();
  const [showQRCode, setShowQRCode] = useState(false);

  useEffect(() => {
    if (weddingId) {
      api.tables.getWeddingsWeddingIdTablesWeddingsWeddingIdTablesGet(weddingId).then(data => {
        setTables(data.data.contents);
      });
      api.guests.getWeddingsWeddingIdGuestsWeddingsWeddingIdGuestsGet(weddingId).then(data => {
        setGuests(data.data.contents.filter(guest => {
          return !guest.table;
        }));
      });
    }
  }, [weddingId]);

  const handleGuestAddToTable = (guestID: string, tableID: string) => {
    api.guests.patchGuestsGuestIdGuestsGuestIdPatch(guestID, {
      table_id: tableID,
    }).then(data => {
      setGuests(guests.filter(guest => {
        return guest.guest_id !== guestID;
      }));
      const updatedTables = tables.map((table) => {
        table.guests = table.guests?.filter(guest => {
          return (guest as Guest).guest_id !== data.data.guest_id
        })
        if (table.table_id === tableID) {
          return {
            ...table,
            guests: [...table.guests || [], data.data],
          };
        }
        return table;
      });
      setTables(updatedTables);
    });
  };

  const handleRemoveTableGuest = (guestID: string, tableID: string) => {
    api.guests.patchGuestsGuestIdGuestsGuestIdPatch(guestID, {
      reset_table: true,
    }).then(data => {
      setGuests([...guests, data.data])
      const updatedTables = tables.map((table) => {
        if (table.table_id === tableID) {
          return {
            ...table,
            guests: table.guests?.filter(guest => {
              return (guest as Guest).guest_id !== guestID;
            }),
          };
        }
        return table;
      });
      setTables(updatedTables);
    })
  };

  // Modal operations
  const handleGuestCreate = () => {
    if (weddingId && newGuestName)
      if (modifyGuestID)
        api.guests.patchGuestsGuestIdGuestsGuestIdPatch(modifyGuestID, {
          guest_name: newGuestName, guest_count: newGuestCount
        }).then(data => {
          const updatedGuests = guests.map((guest) => {
            if (guest.guest_id === modifyGuestID) {
              return {
                ...guest,
                guest_name: newGuestName,
                guest_count: newGuestCount,
              };
            }
            return guest;
          });
          setGuests(updatedGuests);
          handleCancelModal();
        })
      else
        api.guests.postWeddingsWeddingIdGuestsWeddingsWeddingIdGuestsPost(weddingId, {
          guest_name: newGuestName, guest_count: newGuestCount
        }).then(data => {
          setGuests([...guests, data.data]);
          handleCancelModal();
        });
  };

  const handleTableCreate = () => {
    if (weddingId && newTableName)
      if (modifyTableID)
        api.tables.patchTablesTableIdTablesTableIdPatch(modifyTableID, {
          table_name: newTableName,
          table_guest_count: newTableCount,
          table_style: JSON.stringify({
            ...JSON.parse(newTableStyle || "{}"),
            imageUrl: tableStyleMap[selectedTableStyle as keyof typeof tableStyleMap],
          }),
        }).then(data => {
          const updatedTables = tables.map((table) => {
            if (table.table_id === modifyTableID) {
              return {
                ...table,
                table_name: newTableName,
                table_guest_count: newTableCount,
                table_style: JSON.stringify({
                  id: table.table_id,
                  name: newTableName,
                  x: 0,
                  y: 0,
                  width: 80,
                  height: 80,
                  imageUrl: tableStyleMap[selectedTableStyle as keyof typeof tableStyleMap]
                }),
              };
            }
            return table;
          });
          setTables(updatedTables);
          handleCancelModal();
        })
      else
        api.tables.postTablesWeddingsWeddingIdTablesPost(weddingId, {
          table_name: newTableName,
          table_guest_count: newTableCount,
          table_style: JSON.stringify({
            id: "",
            name: newTableName,
            x: 0,
            y: 0,
            width: 80,
            height: 80,
            imageUrl: tableStyleMap[selectedTableStyle as keyof typeof tableStyleMap]
          }),
        }).then(data => {
          setTables([...tables, data.data]);
          handleCancelModal();
        });
  };

  const handleCancelModal = () => {
    setShowGuestCreate(false);
    setShowTableCreate(false);
    setNewGuestName(undefined);
    setNewGuestCount(undefined);
    setModifyGuestID(undefined);
    setNewTableName(undefined);
    setNewTableCount(undefined);
    setSelectedTableStyle(1);
  };

  // Menu operations
  const handleGuestContextMenu = (
    e: React.MouseEvent,
    guest: Guest
  ) => {
    e.preventDefault();

    setContextMenu({
      x: e.clientX,
      y: e.clientY,
      items: [
        {
          label: "Modify",
          onClick: () => {
            setModifyGuestID(guest.guest_id);
            setNewGuestName(guest.guest_name);
            setNewGuestCount(guest.guest_count);
            setShowGuestCreate(true);
          },
        },
        {
          label: "Delete",
          onClick: () => {
            if (window.confirm(`Are you sure you want to delete guest ${guest.guest_name}?`)) {
              api.guests.deleteGuestsGuestIdGuestsGuestIdDelete(guest.guest_id).then(data => {
                setGuests(guests.filter(g => {
                  return g.guest_id !== guest.guest_id;
                }));
              })
            }
          },
        },
      ],
    });
  };

  const handleTableContextMenu = (
    e: React.MouseEvent,
    table: Table
  ) => {
    e.preventDefault();

    setContextMenu({
      x: e.clientX,
      y: e.clientY,
      items: [
        {
          label: "Modify",
          onClick: () => {
            setModifyTableID(table.table_id);
            setNewTableName(table.table_name);
            setNewTableCount(table.table_guest_count);
            if (table.table_style?.includes("round-table"))
              setSelectedTableStyle(1);
            else if (table.table_style?.includes("long-table"))
              setSelectedTableStyle(2);
            else if (table.table_style?.includes("stage"))
              setSelectedTableStyle(3);
            else
              setSelectedTableStyle(1);
            setNewTableStyle(table.table_style);
            setShowTableCreate(true);
          },
        },
        {
          label: "Delete",
          onClick: () => {
            if (window.confirm(`Are you sure you want to delete table ${table.table_name}?`)) {
              api.tables.deleteTablesTableIdTablesTableIdDelete(table.table_id).then(data => {
                setGuests([...guests, ...((table.guests || []) as Guest[])])
                setTables(tables.filter(t => {
                  return t.table_id !== table.table_id;
                }));
              })
            }
          },
        },
      ],
    });
  };

  const menuRef = useRef<HTMLDivElement>(null);
  useOnClickOutside(menuRef, () => {
    setContextMenu(undefined);
  });

  // second navbar functions
  const handleCreateTableView = () => {
    navigate(`/weddings/${weddingId}/table-layout`);
  };

  const handlePublishWedding = () => {
    navigate(`/weddings/${weddingId}/published`);
  }

  const handleGenerateQRCode = () => {
    setShowQRCode(!showQRCode);
  };

  const qrcode = (
    <QRCodeCanvas
      id="qrCode"
      value={`http://wp.ottozhang.com/weddings/${weddingId}/published`}
      size={300}
      bgColor={"#fff"}
      level={"H"}
    />
  );

  return (
    <div className="wedding-page" onContextMenu={(e) => {
      e.preventDefault(); // prevent the default behaviour when right clicked
    }}>
      <NavBar />
      <Navbar className="secondary-navbar" expand="lg">
        <div className="d-flex justify-content-center w-100">
          <Button variant="outline-primary" onClick={handleCreateTableView}>
            Modify Table View
          </Button>
          <Button variant="outline-primary" onClick={handlePublishWedding}>
            Publish My Wedding
          </Button>
          <Button variant="outline-primary" onClick={handleGenerateQRCode}>
            Generate QR code
          </Button>
          {showQRCode && (
            <div className="qrcode-container">
              <div>{qrcode}</div>
              <button className="close-btn" onClick={handleGenerateQRCode}>
                x
              </button>
            </div>
          )}
        </div>
      </Navbar>
      <DndProvider backend={HTML5Backend}>
        <Container>
          <Row>
            <Col sm={4} md={3} className="list-container">
              <div className="guest-list">
                <nav className="list-header">
                  <div className="header-title">Guests</div>
                  <div className="add-btn" onClick={() => setShowGuestCreate(true)} >
                    <i className="fa fa-plus"></i>
                  </div>
                </nav>
                {guests.map((guest) => (
                  <DraggableGuest key={guest.guest_id} guest={guest} onContextMenu={handleGuestContextMenu} />
                ))}
                {guests.length === 0 && <div className="list-item">No guest yet</div>}
              </div>
            </Col>
            <Col sm={8} md={9} className="list-container">
              <div className="table-list">
                <nav className="list-header">
                  <div className="header-title">Tables</div>
                  <div className="add-btn" onClick={() => setShowTableCreate(true)} >
                    <i className="fa fa-plus"></i>
                  </div>
                </nav>
                {tables.map((table) => (
                  <DroppableTable
                    key={table.table_id}
                    table={table}
                    handleDropGuest={handleGuestAddToTable}
                    handleRemoveGuest={handleRemoveTableGuest}
                    onContextMenu={handleTableContextMenu} />
                ))}
                {tables.length === 0 && <div className="list-item">No tables yet</div>}
              </div>
            </Col>
          </Row>
        </Container>
      </DndProvider>
      <Modal show={showGuestCreate} onHide={handleCancelModal}>
        <Modal.Header closeButton>
          <Modal.Title>{modifyGuestID ? "Update Guest" : "Add Guest"}</Modal.Title>
        </Modal.Header>
        <input
          type="text"
          placeholder="Enter guest name"
          value={newGuestName}
          onChange={(e) => setNewGuestName(e.target.value)}
        />
        <input
          type="number"
          placeholder="Enter number of guests"
          value={newGuestCount}
          onChange={(e) => setNewGuestCount(parseInt(e.target.value))}
        />
        <div className="modal-buttons">
          <button className="save-btn" onClick={handleGuestCreate}>
            {modifyGuestID ? "Modify" : "Save"}
          </button>
          <button className="cancel-btn" onClick={handleCancelModal}>
            Cancel
          </button>
        </div>
      </Modal>
      <Modal show={showTableCreate} onHide={handleCancelModal}>
        <Modal.Header closeButton>
          <Modal.Title>{modifyTableID ? "Modify Table" : "Add Table"}</Modal.Title>
        </Modal.Header>
        <input
          type="text"
          placeholder="Enter table name"
          value={newTableName}
          onChange={(e) => setNewTableName(e.target.value)}
        />
        <input
          type="number"
          placeholder="Enter number of guests can sit"
          value={newTableCount}
          onChange={(e) => setNewTableCount(parseInt(e.target.value))}
        />
        <div className="table-style-images">
          {[1, 2, 3].map((index) => (
            <div
              key={index}
              className={`table-style-image-container${index === selectedTableStyle ? ' selected' : ''}`}
              onClick={() => setSelectedTableStyle(index)}
            >
              <img src={tableStyleMap[index as keyof typeof tableStyleMap]} alt={`Table Style ${index}`} />
              <span>Table Style {index}</span>
            </div>
          ))}
        </div>
        <div className="modal-buttons">
          <button className="save-btn" onClick={handleTableCreate}>
            {modifyTableID ? "Modify" : "Save"}
          </button>
          <button className="cancel-btn" onClick={handleCancelModal}>
            Cancel
          </button>
        </div>
      </Modal>
      {contextMenu && (
        <div ref={menuRef}>
          <ContextMenu x={contextMenu.x} y={contextMenu.y} items={contextMenu.items} />
        </div>
      )}
    </div>
  );
}

export default WeddingTableListPage