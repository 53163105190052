import React, { useState, useEffect, useCallback } from "react";
import { useParams } from 'react-router-dom';
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

import { Guest } from "../../api";
import api from '../../components/api';
import NavBar from "../../components/NavBar/NavBar";
import DraggableTableIcon, { TableType } from "../../components/DroppableTableIcon/DroppableTableIcon";
import "./WeddingTableLayoutPage.css";

function WeddingTablePublishedPage() {
  const { weddingId } = useParams<{ weddingId: string }>();

  const [guests, setGuests] = useState<Guest[]>([]);
  const [tables, setTables] = useState<TableType[]>([]);
  const [layoutWidth, setLayoutWidth] = useState<number>(600);
  const [layoutHeight, setLayoutHeight] = useState<number>(600);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [filteredGuests, setFilteredGuests] = useState<Guest[]>([]);
  const [guestListVisible, setGuestListVisible] = useState<boolean>(false);
  const [selectedGuestTable, setSelectedGuestTable] = useState<string | null>(null);

  const handleGuestItemClick = (guest: Guest) => {
    setSearchTerm(guest.guest_name ?? '');
    setGuestListVisible(false);
    setSelectedGuestTable(guest.table ?? null);
  };

  const handleClickOutside = (event: MouseEvent) => {
    const target = event.target as HTMLElement;
    if (!target) return;
    if (!target.closest('.search-bar') && !target.classList.contains('guest-item')) {
      setGuestListVisible(false);
    }
  };
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const loadTableStyle = useCallback(() => {
    if (weddingId) {
      api.weddings.getWeddingWeddingsWeddingIdGet(weddingId).then(data => {
        if (data.data.wedding_style) {
          const wedding_style = JSON.parse(data.data.wedding_style);
          setLayoutWidth(wedding_style.width);
          setLayoutHeight(wedding_style.height);
        }
      });
      api.tables.getWeddingsWeddingIdTablesWeddingsWeddingIdTablesGet(weddingId).then(data => {
        const tableTypes = data.data.contents.map(table => {
          if (table.table_style)
            return JSON.parse(table.table_style)
          else
            return {
              id: table.table_id,
              name: table.table_name,
              x: 0,
              y: 0,
              width: 80,
              height: 80,
              imageUrl: "https://www.ottozhang.com/static/img/icons/round_table.png",
            }
        });
        setTables(tableTypes);
      });
      api.guests.getWeddingsWeddingIdGuestsWeddingsWeddingIdGuestsGet(weddingId).then(data => {
        setGuests(data.data.contents);
      });
    }
  }, [weddingId]);

  useEffect(() => {
    loadTableStyle();
  }, [loadTableStyle]);

  useEffect(() => {
    const container = document.querySelector(".tables-container");
    if (container) {
      (container as HTMLElement).style.width = `${layoutWidth}px`;
      (container as HTMLElement).style.height = `${layoutHeight}px`;
    }
  }, [layoutWidth, layoutHeight]);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchValue = e.target.value;
    setSearchTerm(searchValue);
    filterGuests(searchValue);
  };

  const filterGuests = (searchValue: string) => {
    const filtered = guests.filter((guest) =>
      guest?.guest_name?.toLowerCase().includes(searchValue.toLowerCase())
    );
    setFilteredGuests(filtered);
  };

  return (
    <div>
      <NavBar></NavBar>
      <div className="search-bar" onClick={() => setGuestListVisible(true)}>
        <input
          type="text"
          placeholder="Search table with your name"
          value={searchTerm}
          onChange={handleSearchChange}
        />
      </div>
      {guestListVisible && (
        <div className="search-guest-list">
          {filteredGuests.map((guest) => (
            <div key={guest.guest_id} className="guest-item" onClick={() => handleGuestItemClick(guest)}>
              {guest.guest_name}
            </div>
          ))}
        </div>
      )}
      <DndProvider backend={HTML5Backend}>
        <div className="table-layout-page">
          <div className="tables-container">
            {tables.map((table) => (
              <DraggableTableIcon 
              highlight={selectedGuestTable === table.id} 
              key={table.id} 
              table={table}  />
            ))}
          </div>
        </div>
      </DndProvider>
    </div>
  );
}

export default WeddingTablePublishedPage;
