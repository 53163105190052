import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import api from '../../components/api';
import { login } from '../../components/utils'

import './LoginPage.css';

function LoginPage() {
  const navigate = useNavigate();

  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');

  return (
    <div className="login-page">
      <div className="login-card">
        <h1 className="login-title">Welcome back!</h1>
        <div className="form-group">
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            placeholder="Email Address"
            className="login-input"
          />
        </div>
        <div className="form-group">
          <input
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            placeholder="Password"
            className="login-input"
          />
        </div>
        <button className="login-button" onClick={() => {
          api.root.tokenTokenPost(email, password).then(data => {
            login(data.data.access_token);
            navigate('/weddings');
          });
        }}>Log in</button>
      </div>
    </div>
  );
}

export default LoginPage;
