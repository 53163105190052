import React from 'react';
import { Rnd } from 'react-rnd';

export type TableType = {
  id: string;
  name: string;
  x: number;
  y: number;
  width: number;
  height: number;
  imageUrl: string;
};

type DraggableTableIconProps = {
  table: TableType;
  onDragEnd?: (tableId: string, x: number, y: number) => void;
  onResize?: (tableId: string, width: number, height: number) => void;
  highlight?: boolean;
};

function DraggableTableIcon({ table, onDragEnd, onResize, highlight }: DraggableTableIconProps) {
  const handleDragStop = (e: any, data: any) => {
    if (onDragEnd)
      onDragEnd(table.id, data.x, data.y);
  };

  const handleResizeStop = (e: any, dir: any, ref: any) => {
    if (onResize)
      onResize(table.id, ref.offsetWidth, ref.offsetHeight);
  };
  const gridSize = 20;

  return (
    <Rnd
      size={{ width: table.width, height: table.height }}
      position={{ x: table.x, y: table.y }}
      onDragStop={handleDragStop}
      onResizeStop={handleResizeStop}
      lockAspectRatio={true}
      dragGrid={[gridSize, gridSize]}
      resizeGrid={[gridSize, gridSize]}
      style={{
        backgroundImage: `url(${table.imageUrl})`,
        backgroundSize: 'cover',
        border: highlight ? '3px solid #ff637d' : 'none',
      }}
    >
      <div
        className="table-content"
        style={{
          width: '100%',
          height: '100%',
          backgroundImage: `url(${table.imageUrl})`,
          backgroundSize: 'cover',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {table.name}
      </div>
    </Rnd>
  );
}

export default DraggableTableIcon;
