/* tslint:disable */
/* eslint-disable */
/**
 * Wedding_panner
 *      Wedding Planner API      This API documentation is fully compatible with OpenAPI specification.      For more information, please visit https://ottozhang.com     
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface Guest
 */
export interface Guest {
    /**
     * 
     * @type {string}
     * @memberof Guest
     */
    'self_link': string;
    /**
     * 
     * @type {Kind}
     * @memberof Guest
     */
    'kind': Kind;
    /**
     * 
     * @type {string}
     * @memberof Guest
     */
    'guest_name'?: string;
    /**
     * 
     * @type {number}
     * @memberof Guest
     */
    'guest_count'?: number;
    /**
     * 
     * @type {string}
     * @memberof Guest
     */
    'guest_id': string;
    /**
     * 
     * @type {string}
     * @memberof Guest
     */
    'wedding': string;
    /**
     * 
     * @type {string}
     * @memberof Guest
     */
    'table'?: string;
}


/**
 * 
 * @export
 * @interface GuestCollections
 */
export interface GuestCollections {
    /**
     * 
     * @type {string}
     * @memberof GuestCollections
     */
    'self_link': string;
    /**
     * 
     * @type {Kind}
     * @memberof GuestCollections
     */
    'kind': Kind;
    /**
     * 
     * @type {string}
     * @memberof GuestCollections
     */
    'page_token': string;
    /**
     * 
     * @type {string}
     * @memberof GuestCollections
     */
    'next_page_token': string;
    /**
     * 
     * @type {number}
     * @memberof GuestCollections
     */
    'page_size': number;
    /**
     * 
     * @type {number}
     * @memberof GuestCollections
     */
    'total_size': number;
    /**
     * 
     * @type {number}
     * @memberof GuestCollections
     */
    'total_pages': number;
    /**
     * 
     * @type {Array<Guest>}
     * @memberof GuestCollections
     */
    'contents': Array<Guest>;
}


/**
 * 
 * @export
 * @interface GuestCreate
 */
export interface GuestCreate {
    /**
     * 
     * @type {string}
     * @memberof GuestCreate
     */
    'guest_name': string;
    /**
     * 
     * @type {number}
     * @memberof GuestCreate
     */
    'guest_count'?: number;
    /**
     * 
     * @type {string}
     * @memberof GuestCreate
     */
    'table_id'?: string;
}
/**
 * 
 * @export
 * @interface GuestPatch
 */
export interface GuestPatch {
    /**
     * 
     * @type {string}
     * @memberof GuestPatch
     */
    'guest_name'?: string;
    /**
     * 
     * @type {number}
     * @memberof GuestPatch
     */
    'guest_count'?: number;
    /**
     * 
     * @type {string}
     * @memberof GuestPatch
     */
    'table_id'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof GuestPatch
     */
    'reset_table'?: boolean;
}
/**
 * 
 * @export
 * @interface HTTPValidationError
 */
export interface HTTPValidationError {
    /**
     * 
     * @type {Array<ValidationError>}
     * @memberof HTTPValidationError
     */
    'detail'?: Array<ValidationError>;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export const Kind = {
    Collection: 'collection',
    Guest: 'guest',
    Table: 'table',
    User: 'user',
    Wedding: 'wedding'
} as const;

export type Kind = typeof Kind[keyof typeof Kind];


/**
 * 
 * @export
 * @interface LoginResponse
 */
export interface LoginResponse {
    /**
     * 
     * @type {string}
     * @memberof LoginResponse
     */
    'access_token': string;
    /**
     * 
     * @type {string}
     * @memberof LoginResponse
     */
    'token_type'?: LoginResponseTokenTypeEnum;
}

export const LoginResponseTokenTypeEnum = {
    Bearer: 'bearer'
} as const;

export type LoginResponseTokenTypeEnum = typeof LoginResponseTokenTypeEnum[keyof typeof LoginResponseTokenTypeEnum];

/**
 * 
 * @export
 * @interface ResetPasswordRequest
 */
export interface ResetPasswordRequest {
    /**
     * 
     * @type {string}
     * @memberof ResetPasswordRequest
     */
    'old_password': string;
    /**
     * 
     * @type {string}
     * @memberof ResetPasswordRequest
     */
    'new_password': string;
}
/**
 * 
 * @export
 * @interface ResetPasswordResponse
 */
export interface ResetPasswordResponse {
    /**
     * 
     * @type {boolean}
     * @memberof ResetPasswordResponse
     */
    'success'?: boolean;
}
/**
 * 
 * @export
 * @interface Table
 */
export interface Table {
    /**
     * 
     * @type {string}
     * @memberof Table
     */
    'self_link': string;
    /**
     * 
     * @type {Kind}
     * @memberof Table
     */
    'kind': Kind;
    /**
     * 
     * @type {string}
     * @memberof Table
     */
    'table_name': string;
    /**
     * 
     * @type {number}
     * @memberof Table
     */
    'table_guest_count'?: number;
    /**
     * 
     * @type {string}
     * @memberof Table
     */
    'table_style'?: string;
    /**
     * 
     * @type {string}
     * @memberof Table
     */
    'table_id': string;
    /**
     * 
     * @type {string}
     * @memberof Table
     */
    'Wedding': string;
    /**
     * 
     * @type {Array<object>}
     * @memberof Table
     */
    'guests'?: Array<object>;
}


/**
 * 
 * @export
 * @interface TableCollections
 */
export interface TableCollections {
    /**
     * 
     * @type {string}
     * @memberof TableCollections
     */
    'self_link': string;
    /**
     * 
     * @type {Kind}
     * @memberof TableCollections
     */
    'kind': Kind;
    /**
     * 
     * @type {string}
     * @memberof TableCollections
     */
    'page_token': string;
    /**
     * 
     * @type {string}
     * @memberof TableCollections
     */
    'next_page_token': string;
    /**
     * 
     * @type {number}
     * @memberof TableCollections
     */
    'page_size': number;
    /**
     * 
     * @type {number}
     * @memberof TableCollections
     */
    'total_size': number;
    /**
     * 
     * @type {number}
     * @memberof TableCollections
     */
    'total_pages': number;
    /**
     * 
     * @type {Array<Table>}
     * @memberof TableCollections
     */
    'contents': Array<Table>;
}


/**
 * 
 * @export
 * @interface TableCreate
 */
export interface TableCreate {
    /**
     * 
     * @type {string}
     * @memberof TableCreate
     */
    'table_name': string;
    /**
     * 
     * @type {number}
     * @memberof TableCreate
     */
    'table_guest_count'?: number;
    /**
     * 
     * @type {string}
     * @memberof TableCreate
     */
    'table_style'?: string;
}
/**
 * 
 * @export
 * @interface TablePatch
 */
export interface TablePatch {
    /**
     * 
     * @type {string}
     * @memberof TablePatch
     */
    'table_name'?: string;
    /**
     * 
     * @type {number}
     * @memberof TablePatch
     */
    'table_guest_count'?: number;
    /**
     * 
     * @type {string}
     * @memberof TablePatch
     */
    'table_style'?: string;
}
/**
 * 
 * @export
 * @interface User
 */
export interface User {
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'self_link': string;
    /**
     * 
     * @type {Kind}
     * @memberof User
     */
    'kind': Kind;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'user_email': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'first_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'last_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'user_id': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'date_joined': string;
    /**
     * 
     * @type {UserRole}
     * @memberof User
     */
    'role': UserRole;
    /**
     * 
     * @type {object}
     * @memberof User
     */
    'extras'?: object;
    /**
     * 
     * @type {Array<object>}
     * @memberof User
     */
    'weddings': Array<object>;
}


/**
 * 
 * @export
 * @interface UserCollections
 */
export interface UserCollections {
    /**
     * 
     * @type {string}
     * @memberof UserCollections
     */
    'self_link': string;
    /**
     * 
     * @type {Kind}
     * @memberof UserCollections
     */
    'kind': Kind;
    /**
     * 
     * @type {string}
     * @memberof UserCollections
     */
    'page_token': string;
    /**
     * 
     * @type {string}
     * @memberof UserCollections
     */
    'next_page_token': string;
    /**
     * 
     * @type {number}
     * @memberof UserCollections
     */
    'page_size': number;
    /**
     * 
     * @type {number}
     * @memberof UserCollections
     */
    'total_size': number;
    /**
     * 
     * @type {number}
     * @memberof UserCollections
     */
    'total_pages': number;
    /**
     * 
     * @type {Array<User>}
     * @memberof UserCollections
     */
    'contents': Array<User>;
}


/**
 * 
 * @export
 * @interface UserCreate
 */
export interface UserCreate {
    /**
     * 
     * @type {string}
     * @memberof UserCreate
     */
    'user_email': string;
    /**
     * 
     * @type {string}
     * @memberof UserCreate
     */
    'first_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserCreate
     */
    'last_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserCreate
     */
    'password': string;
    /**
     * 
     * @type {UserRole}
     * @memberof UserCreate
     */
    'role'?: UserRole;
    /**
     * 
     * @type {object}
     * @memberof UserCreate
     */
    'extras'?: object;
}


/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export const UserRole = {
    Admin: 'admin',
    Planner: 'planner'
} as const;

export type UserRole = typeof UserRole[keyof typeof UserRole];


/**
 * 
 * @export
 * @interface ValidationError
 */
export interface ValidationError {
    /**
     * 
     * @type {Array<string>}
     * @memberof ValidationError
     */
    'loc': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ValidationError
     */
    'msg': string;
    /**
     * 
     * @type {string}
     * @memberof ValidationError
     */
    'type': string;
}
/**
 * 
 * @export
 * @interface Wedding
 */
export interface Wedding {
    /**
     * 
     * @type {string}
     * @memberof Wedding
     */
    'self_link': string;
    /**
     * 
     * @type {Kind}
     * @memberof Wedding
     */
    'kind': Kind;
    /**
     * 
     * @type {WeddingStatus}
     * @memberof Wedding
     */
    'status'?: WeddingStatus;
    /**
     * 
     * @type {string}
     * @memberof Wedding
     */
    'wedding_style'?: string;
    /**
     * 
     * @type {string}
     * @memberof Wedding
     */
    'wedding_date': string;
    /**
     * 
     * @type {string}
     * @memberof Wedding
     */
    'wedding_id': string;
    /**
     * 
     * @type {string}
     * @memberof Wedding
     */
    'user': string;
}


/**
 * 
 * @export
 * @interface WeddingCollections
 */
export interface WeddingCollections {
    /**
     * 
     * @type {string}
     * @memberof WeddingCollections
     */
    'self_link': string;
    /**
     * 
     * @type {Kind}
     * @memberof WeddingCollections
     */
    'kind': Kind;
    /**
     * 
     * @type {string}
     * @memberof WeddingCollections
     */
    'page_token': string;
    /**
     * 
     * @type {string}
     * @memberof WeddingCollections
     */
    'next_page_token': string;
    /**
     * 
     * @type {number}
     * @memberof WeddingCollections
     */
    'page_size': number;
    /**
     * 
     * @type {number}
     * @memberof WeddingCollections
     */
    'total_size': number;
    /**
     * 
     * @type {number}
     * @memberof WeddingCollections
     */
    'total_pages': number;
    /**
     * 
     * @type {Array<Wedding>}
     * @memberof WeddingCollections
     */
    'contents': Array<Wedding>;
}


/**
 * 
 * @export
 * @interface WeddingCreate
 */
export interface WeddingCreate {
    /**
     * 
     * @type {string}
     * @memberof WeddingCreate
     */
    'wedding_date': string;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export const WeddingStatus = {
    Planning: 'planning',
    Published: 'published',
    Expired: 'expired'
} as const;

export type WeddingStatus = typeof WeddingStatus[keyof typeof WeddingStatus];


/**
 * 
 * @export
 * @interface WeedingPatch
 */
export interface WeedingPatch {
    /**
     * 
     * @type {WeddingStatus}
     * @memberof WeedingPatch
     */
    'status'?: WeddingStatus;
    /**
     * 
     * @type {string}
     * @memberof WeedingPatch
     */
    'wedding_style'?: string;
}



/**
 * GuestsApi - axios parameter creator
 * @export
 */
export const GuestsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Delete Guests Guest Id
         * @param {string} guestId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteGuestsGuestIdGuestsGuestIdDelete: async (guestId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guestId' is not null or undefined
            assertParamExists('deleteGuestsGuestIdGuestsGuestIdDelete', 'guestId', guestId)
            const localVarPath = `/guests/{guest_id}/`
                .replace(`{${"guest_id"}}`, encodeURIComponent(String(guestId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication _Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "_Bearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Tables Table Id Guests
         * @param {string} tableId 
         * @param {string} [pageToken] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTablesTableIdGuestsTablesTableIdGuestsGet: async (tableId: string, pageToken?: string, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tableId' is not null or undefined
            assertParamExists('getTablesTableIdGuestsTablesTableIdGuestsGet', 'tableId', tableId)
            const localVarPath = `/tables/{table_id}/guests`
                .replace(`{${"table_id"}}`, encodeURIComponent(String(tableId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication _Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "_Bearer", [], configuration)

            if (pageToken !== undefined) {
                localVarQueryParameter['page_token'] = pageToken;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Weddings Wedding Id Guests
         * @param {string} weddingId 
         * @param {string} [pageToken] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWeddingsWeddingIdGuestsWeddingsWeddingIdGuestsGet: async (weddingId: string, pageToken?: string, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'weddingId' is not null or undefined
            assertParamExists('getWeddingsWeddingIdGuestsWeddingsWeddingIdGuestsGet', 'weddingId', weddingId)
            const localVarPath = `/weddings/{wedding_id}/guests/`
                .replace(`{${"wedding_id"}}`, encodeURIComponent(String(weddingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pageToken !== undefined) {
                localVarQueryParameter['page_token'] = pageToken;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Patch Guests Guest Id
         * @param {string} guestId 
         * @param {GuestPatch} guestPatch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchGuestsGuestIdGuestsGuestIdPatch: async (guestId: string, guestPatch: GuestPatch, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guestId' is not null or undefined
            assertParamExists('patchGuestsGuestIdGuestsGuestIdPatch', 'guestId', guestId)
            // verify required parameter 'guestPatch' is not null or undefined
            assertParamExists('patchGuestsGuestIdGuestsGuestIdPatch', 'guestPatch', guestPatch)
            const localVarPath = `/guests/{guest_id}/`
                .replace(`{${"guest_id"}}`, encodeURIComponent(String(guestId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication _Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "_Bearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(guestPatch, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Post Weddings Wedding Id Guests
         * @param {string} weddingId 
         * @param {GuestCreate} guestCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postWeddingsWeddingIdGuestsWeddingsWeddingIdGuestsPost: async (weddingId: string, guestCreate: GuestCreate, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'weddingId' is not null or undefined
            assertParamExists('postWeddingsWeddingIdGuestsWeddingsWeddingIdGuestsPost', 'weddingId', weddingId)
            // verify required parameter 'guestCreate' is not null or undefined
            assertParamExists('postWeddingsWeddingIdGuestsWeddingsWeddingIdGuestsPost', 'guestCreate', guestCreate)
            const localVarPath = `/weddings/{wedding_id}/guests/`
                .replace(`{${"wedding_id"}}`, encodeURIComponent(String(weddingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication _Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "_Bearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(guestCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GuestsApi - functional programming interface
 * @export
 */
export const GuestsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = GuestsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Delete Guests Guest Id
         * @param {string} guestId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteGuestsGuestIdGuestsGuestIdDelete(guestId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteGuestsGuestIdGuestsGuestIdDelete(guestId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Tables Table Id Guests
         * @param {string} tableId 
         * @param {string} [pageToken] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTablesTableIdGuestsTablesTableIdGuestsGet(tableId: string, pageToken?: string, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GuestCollections>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTablesTableIdGuestsTablesTableIdGuestsGet(tableId, pageToken, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Weddings Wedding Id Guests
         * @param {string} weddingId 
         * @param {string} [pageToken] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWeddingsWeddingIdGuestsWeddingsWeddingIdGuestsGet(weddingId: string, pageToken?: string, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GuestCollections>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWeddingsWeddingIdGuestsWeddingsWeddingIdGuestsGet(weddingId, pageToken, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Patch Guests Guest Id
         * @param {string} guestId 
         * @param {GuestPatch} guestPatch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchGuestsGuestIdGuestsGuestIdPatch(guestId: string, guestPatch: GuestPatch, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Guest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchGuestsGuestIdGuestsGuestIdPatch(guestId, guestPatch, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Post Weddings Wedding Id Guests
         * @param {string} weddingId 
         * @param {GuestCreate} guestCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postWeddingsWeddingIdGuestsWeddingsWeddingIdGuestsPost(weddingId: string, guestCreate: GuestCreate, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Guest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postWeddingsWeddingIdGuestsWeddingsWeddingIdGuestsPost(weddingId, guestCreate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * GuestsApi - factory interface
 * @export
 */
export const GuestsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GuestsApiFp(configuration)
    return {
        /**
         * 
         * @summary Delete Guests Guest Id
         * @param {string} guestId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteGuestsGuestIdGuestsGuestIdDelete(guestId: string, options?: any): AxiosPromise<any> {
            return localVarFp.deleteGuestsGuestIdGuestsGuestIdDelete(guestId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Tables Table Id Guests
         * @param {string} tableId 
         * @param {string} [pageToken] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTablesTableIdGuestsTablesTableIdGuestsGet(tableId: string, pageToken?: string, pageSize?: number, options?: any): AxiosPromise<GuestCollections> {
            return localVarFp.getTablesTableIdGuestsTablesTableIdGuestsGet(tableId, pageToken, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Weddings Wedding Id Guests
         * @param {string} weddingId 
         * @param {string} [pageToken] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWeddingsWeddingIdGuestsWeddingsWeddingIdGuestsGet(weddingId: string, pageToken?: string, pageSize?: number, options?: any): AxiosPromise<GuestCollections> {
            return localVarFp.getWeddingsWeddingIdGuestsWeddingsWeddingIdGuestsGet(weddingId, pageToken, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Patch Guests Guest Id
         * @param {string} guestId 
         * @param {GuestPatch} guestPatch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchGuestsGuestIdGuestsGuestIdPatch(guestId: string, guestPatch: GuestPatch, options?: any): AxiosPromise<Guest> {
            return localVarFp.patchGuestsGuestIdGuestsGuestIdPatch(guestId, guestPatch, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Post Weddings Wedding Id Guests
         * @param {string} weddingId 
         * @param {GuestCreate} guestCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postWeddingsWeddingIdGuestsWeddingsWeddingIdGuestsPost(weddingId: string, guestCreate: GuestCreate, options?: any): AxiosPromise<Guest> {
            return localVarFp.postWeddingsWeddingIdGuestsWeddingsWeddingIdGuestsPost(weddingId, guestCreate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * GuestsApi - object-oriented interface
 * @export
 * @class GuestsApi
 * @extends {BaseAPI}
 */
export class GuestsApi extends BaseAPI {
    /**
     * 
     * @summary Delete Guests Guest Id
     * @param {string} guestId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GuestsApi
     */
    public deleteGuestsGuestIdGuestsGuestIdDelete(guestId: string, options?: AxiosRequestConfig) {
        return GuestsApiFp(this.configuration).deleteGuestsGuestIdGuestsGuestIdDelete(guestId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Tables Table Id Guests
     * @param {string} tableId 
     * @param {string} [pageToken] 
     * @param {number} [pageSize] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GuestsApi
     */
    public getTablesTableIdGuestsTablesTableIdGuestsGet(tableId: string, pageToken?: string, pageSize?: number, options?: AxiosRequestConfig) {
        return GuestsApiFp(this.configuration).getTablesTableIdGuestsTablesTableIdGuestsGet(tableId, pageToken, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Weddings Wedding Id Guests
     * @param {string} weddingId 
     * @param {string} [pageToken] 
     * @param {number} [pageSize] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GuestsApi
     */
    public getWeddingsWeddingIdGuestsWeddingsWeddingIdGuestsGet(weddingId: string, pageToken?: string, pageSize?: number, options?: AxiosRequestConfig) {
        return GuestsApiFp(this.configuration).getWeddingsWeddingIdGuestsWeddingsWeddingIdGuestsGet(weddingId, pageToken, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Patch Guests Guest Id
     * @param {string} guestId 
     * @param {GuestPatch} guestPatch 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GuestsApi
     */
    public patchGuestsGuestIdGuestsGuestIdPatch(guestId: string, guestPatch: GuestPatch, options?: AxiosRequestConfig) {
        return GuestsApiFp(this.configuration).patchGuestsGuestIdGuestsGuestIdPatch(guestId, guestPatch, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Post Weddings Wedding Id Guests
     * @param {string} weddingId 
     * @param {GuestCreate} guestCreate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GuestsApi
     */
    public postWeddingsWeddingIdGuestsWeddingsWeddingIdGuestsPost(weddingId: string, guestCreate: GuestCreate, options?: AxiosRequestConfig) {
        return GuestsApiFp(this.configuration).postWeddingsWeddingIdGuestsWeddingsWeddingIdGuestsPost(weddingId, guestCreate, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * RootApi - axios parameter creator
 * @export
 */
export const RootApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Read Root
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readRootGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Token
         * @param {string} username 
         * @param {string} password 
         * @param {string} [grantType] 
         * @param {string} [scope] 
         * @param {string} [clientId] 
         * @param {string} [clientSecret] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tokenTokenPost: async (username: string, password: string, grantType?: string, scope?: string, clientId?: string, clientSecret?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'username' is not null or undefined
            assertParamExists('tokenTokenPost', 'username', username)
            // verify required parameter 'password' is not null or undefined
            assertParamExists('tokenTokenPost', 'password', password)
            const localVarPath = `/token/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new URLSearchParams();


            if (grantType !== undefined) { 
                localVarFormParams.set('grant_type', grantType as any);
            }
    
            if (username !== undefined) { 
                localVarFormParams.set('username', username as any);
            }
    
            if (password !== undefined) { 
                localVarFormParams.set('password', password as any);
            }
    
            if (scope !== undefined) { 
                localVarFormParams.set('scope', scope as any);
            }
    
            if (clientId !== undefined) { 
                localVarFormParams.set('client_id', clientId as any);
            }
    
            if (clientSecret !== undefined) { 
                localVarFormParams.set('client_secret', clientSecret as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams.toString();

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RootApi - functional programming interface
 * @export
 */
export const RootApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RootApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Read Root
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readRootGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readRootGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Token
         * @param {string} username 
         * @param {string} password 
         * @param {string} [grantType] 
         * @param {string} [scope] 
         * @param {string} [clientId] 
         * @param {string} [clientSecret] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tokenTokenPost(username: string, password: string, grantType?: string, scope?: string, clientId?: string, clientSecret?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoginResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tokenTokenPost(username, password, grantType, scope, clientId, clientSecret, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RootApi - factory interface
 * @export
 */
export const RootApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RootApiFp(configuration)
    return {
        /**
         * 
         * @summary Read Root
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readRootGet(options?: any): AxiosPromise<any> {
            return localVarFp.readRootGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Token
         * @param {string} username 
         * @param {string} password 
         * @param {string} [grantType] 
         * @param {string} [scope] 
         * @param {string} [clientId] 
         * @param {string} [clientSecret] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tokenTokenPost(username: string, password: string, grantType?: string, scope?: string, clientId?: string, clientSecret?: string, options?: any): AxiosPromise<LoginResponse> {
            return localVarFp.tokenTokenPost(username, password, grantType, scope, clientId, clientSecret, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RootApi - object-oriented interface
 * @export
 * @class RootApi
 * @extends {BaseAPI}
 */
export class RootApi extends BaseAPI {
    /**
     * 
     * @summary Read Root
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RootApi
     */
    public readRootGet(options?: AxiosRequestConfig) {
        return RootApiFp(this.configuration).readRootGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Token
     * @param {string} username 
     * @param {string} password 
     * @param {string} [grantType] 
     * @param {string} [scope] 
     * @param {string} [clientId] 
     * @param {string} [clientSecret] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RootApi
     */
    public tokenTokenPost(username: string, password: string, grantType?: string, scope?: string, clientId?: string, clientSecret?: string, options?: AxiosRequestConfig) {
        return RootApiFp(this.configuration).tokenTokenPost(username, password, grantType, scope, clientId, clientSecret, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TablesApi - axios parameter creator
 * @export
 */
export const TablesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Delete Tables Table Id
         * @param {string} tableId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTablesTableIdTablesTableIdDelete: async (tableId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tableId' is not null or undefined
            assertParamExists('deleteTablesTableIdTablesTableIdDelete', 'tableId', tableId)
            const localVarPath = `/tables/{table_id}/`
                .replace(`{${"table_id"}}`, encodeURIComponent(String(tableId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication _Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "_Bearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Tables Table Id
         * @param {string} tableId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTablesTableIdTablesTableIdGet: async (tableId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tableId' is not null or undefined
            assertParamExists('getTablesTableIdTablesTableIdGet', 'tableId', tableId)
            const localVarPath = `/tables/{table_id}/`
                .replace(`{${"table_id"}}`, encodeURIComponent(String(tableId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication _Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "_Bearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Weddings Wedding Id Tables
         * @param {string} weddingId 
         * @param {string} [pageToken] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWeddingsWeddingIdTablesWeddingsWeddingIdTablesGet: async (weddingId: string, pageToken?: string, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'weddingId' is not null or undefined
            assertParamExists('getWeddingsWeddingIdTablesWeddingsWeddingIdTablesGet', 'weddingId', weddingId)
            const localVarPath = `/weddings/{wedding_id}/tables/`
                .replace(`{${"wedding_id"}}`, encodeURIComponent(String(weddingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pageToken !== undefined) {
                localVarQueryParameter['page_token'] = pageToken;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Patch Tables Table Id
         * @param {string} tableId 
         * @param {TablePatch} tablePatch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchTablesTableIdTablesTableIdPatch: async (tableId: string, tablePatch: TablePatch, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tableId' is not null or undefined
            assertParamExists('patchTablesTableIdTablesTableIdPatch', 'tableId', tableId)
            // verify required parameter 'tablePatch' is not null or undefined
            assertParamExists('patchTablesTableIdTablesTableIdPatch', 'tablePatch', tablePatch)
            const localVarPath = `/tables/{table_id}/`
                .replace(`{${"table_id"}}`, encodeURIComponent(String(tableId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication _Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "_Bearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tablePatch, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Post Tables
         * @param {string} weddingId 
         * @param {TableCreate} tableCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postTablesWeddingsWeddingIdTablesPost: async (weddingId: string, tableCreate: TableCreate, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'weddingId' is not null or undefined
            assertParamExists('postTablesWeddingsWeddingIdTablesPost', 'weddingId', weddingId)
            // verify required parameter 'tableCreate' is not null or undefined
            assertParamExists('postTablesWeddingsWeddingIdTablesPost', 'tableCreate', tableCreate)
            const localVarPath = `/weddings/{wedding_id}/tables/`
                .replace(`{${"wedding_id"}}`, encodeURIComponent(String(weddingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication _Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "_Bearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tableCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TablesApi - functional programming interface
 * @export
 */
export const TablesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TablesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Delete Tables Table Id
         * @param {string} tableId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteTablesTableIdTablesTableIdDelete(tableId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteTablesTableIdTablesTableIdDelete(tableId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Tables Table Id
         * @param {string} tableId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTablesTableIdTablesTableIdGet(tableId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Table>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTablesTableIdTablesTableIdGet(tableId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Weddings Wedding Id Tables
         * @param {string} weddingId 
         * @param {string} [pageToken] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWeddingsWeddingIdTablesWeddingsWeddingIdTablesGet(weddingId: string, pageToken?: string, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TableCollections>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWeddingsWeddingIdTablesWeddingsWeddingIdTablesGet(weddingId, pageToken, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Patch Tables Table Id
         * @param {string} tableId 
         * @param {TablePatch} tablePatch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchTablesTableIdTablesTableIdPatch(tableId: string, tablePatch: TablePatch, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Table>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchTablesTableIdTablesTableIdPatch(tableId, tablePatch, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Post Tables
         * @param {string} weddingId 
         * @param {TableCreate} tableCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postTablesWeddingsWeddingIdTablesPost(weddingId: string, tableCreate: TableCreate, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Table>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postTablesWeddingsWeddingIdTablesPost(weddingId, tableCreate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TablesApi - factory interface
 * @export
 */
export const TablesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TablesApiFp(configuration)
    return {
        /**
         * 
         * @summary Delete Tables Table Id
         * @param {string} tableId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTablesTableIdTablesTableIdDelete(tableId: string, options?: any): AxiosPromise<any> {
            return localVarFp.deleteTablesTableIdTablesTableIdDelete(tableId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Tables Table Id
         * @param {string} tableId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTablesTableIdTablesTableIdGet(tableId: string, options?: any): AxiosPromise<Table> {
            return localVarFp.getTablesTableIdTablesTableIdGet(tableId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Weddings Wedding Id Tables
         * @param {string} weddingId 
         * @param {string} [pageToken] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWeddingsWeddingIdTablesWeddingsWeddingIdTablesGet(weddingId: string, pageToken?: string, pageSize?: number, options?: any): AxiosPromise<TableCollections> {
            return localVarFp.getWeddingsWeddingIdTablesWeddingsWeddingIdTablesGet(weddingId, pageToken, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Patch Tables Table Id
         * @param {string} tableId 
         * @param {TablePatch} tablePatch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchTablesTableIdTablesTableIdPatch(tableId: string, tablePatch: TablePatch, options?: any): AxiosPromise<Table> {
            return localVarFp.patchTablesTableIdTablesTableIdPatch(tableId, tablePatch, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Post Tables
         * @param {string} weddingId 
         * @param {TableCreate} tableCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postTablesWeddingsWeddingIdTablesPost(weddingId: string, tableCreate: TableCreate, options?: any): AxiosPromise<Table> {
            return localVarFp.postTablesWeddingsWeddingIdTablesPost(weddingId, tableCreate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TablesApi - object-oriented interface
 * @export
 * @class TablesApi
 * @extends {BaseAPI}
 */
export class TablesApi extends BaseAPI {
    /**
     * 
     * @summary Delete Tables Table Id
     * @param {string} tableId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TablesApi
     */
    public deleteTablesTableIdTablesTableIdDelete(tableId: string, options?: AxiosRequestConfig) {
        return TablesApiFp(this.configuration).deleteTablesTableIdTablesTableIdDelete(tableId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Tables Table Id
     * @param {string} tableId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TablesApi
     */
    public getTablesTableIdTablesTableIdGet(tableId: string, options?: AxiosRequestConfig) {
        return TablesApiFp(this.configuration).getTablesTableIdTablesTableIdGet(tableId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Weddings Wedding Id Tables
     * @param {string} weddingId 
     * @param {string} [pageToken] 
     * @param {number} [pageSize] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TablesApi
     */
    public getWeddingsWeddingIdTablesWeddingsWeddingIdTablesGet(weddingId: string, pageToken?: string, pageSize?: number, options?: AxiosRequestConfig) {
        return TablesApiFp(this.configuration).getWeddingsWeddingIdTablesWeddingsWeddingIdTablesGet(weddingId, pageToken, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Patch Tables Table Id
     * @param {string} tableId 
     * @param {TablePatch} tablePatch 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TablesApi
     */
    public patchTablesTableIdTablesTableIdPatch(tableId: string, tablePatch: TablePatch, options?: AxiosRequestConfig) {
        return TablesApiFp(this.configuration).patchTablesTableIdTablesTableIdPatch(tableId, tablePatch, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Post Tables
     * @param {string} weddingId 
     * @param {TableCreate} tableCreate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TablesApi
     */
    public postTablesWeddingsWeddingIdTablesPost(weddingId: string, tableCreate: TableCreate, options?: AxiosRequestConfig) {
        return TablesApiFp(this.configuration).postTablesWeddingsWeddingIdTablesPost(weddingId, tableCreate, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UsersApi - axios parameter creator
 * @export
 */
export const UsersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get Users User Id
         * @param {string} userId 
         * @param {string} [pageToken] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsersUserIdUsersUserIdGet: async (userId: string, pageToken?: string, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getUsersUserIdUsersUserIdGet', 'userId', userId)
            const localVarPath = `/users/{user_id}`
                .replace(`{${"user_id"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication _Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "_Bearer", [], configuration)

            if (pageToken !== undefined) {
                localVarQueryParameter['page_token'] = pageToken;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Post User
         * @param {UserCreate} userCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postUserUserPost: async (userCreate: UserCreate, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userCreate' is not null or undefined
            assertParamExists('postUserUserPost', 'userCreate', userCreate)
            const localVarPath = `/user/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication _Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "_Bearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Reset User Password
         * @param {ResetPasswordRequest} resetPasswordRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetUserPasswordResetPasswordPost: async (resetPasswordRequest: ResetPasswordRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'resetPasswordRequest' is not null or undefined
            assertParamExists('resetUserPasswordResetPasswordPost', 'resetPasswordRequest', resetPasswordRequest)
            const localVarPath = `/reset_password/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication _Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "_Bearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(resetPasswordRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary User
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userUserGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/user/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication _Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "_Bearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Users
         * @param {string} [pageToken] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersUsersGet: async (pageToken?: string, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication _Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "_Bearer", [], configuration)

            if (pageToken !== undefined) {
                localVarQueryParameter['page_token'] = pageToken;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UsersApi - functional programming interface
 * @export
 */
export const UsersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UsersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get Users User Id
         * @param {string} userId 
         * @param {string} [pageToken] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUsersUserIdUsersUserIdGet(userId: string, pageToken?: string, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUsersUserIdUsersUserIdGet(userId, pageToken, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Post User
         * @param {UserCreate} userCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postUserUserPost(userCreate: UserCreate, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postUserUserPost(userCreate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Reset User Password
         * @param {ResetPasswordRequest} resetPasswordRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resetUserPasswordResetPasswordPost(resetPasswordRequest: ResetPasswordRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResetPasswordResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resetUserPasswordResetPasswordPost(resetPasswordRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary User
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userUserGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userUserGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Users
         * @param {string} [pageToken] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersUsersGet(pageToken?: string, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserCollections>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersUsersGet(pageToken, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UsersApi - factory interface
 * @export
 */
export const UsersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UsersApiFp(configuration)
    return {
        /**
         * 
         * @summary Get Users User Id
         * @param {string} userId 
         * @param {string} [pageToken] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsersUserIdUsersUserIdGet(userId: string, pageToken?: string, pageSize?: number, options?: any): AxiosPromise<User> {
            return localVarFp.getUsersUserIdUsersUserIdGet(userId, pageToken, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Post User
         * @param {UserCreate} userCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postUserUserPost(userCreate: UserCreate, options?: any): AxiosPromise<User> {
            return localVarFp.postUserUserPost(userCreate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Reset User Password
         * @param {ResetPasswordRequest} resetPasswordRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetUserPasswordResetPasswordPost(resetPasswordRequest: ResetPasswordRequest, options?: any): AxiosPromise<ResetPasswordResponse> {
            return localVarFp.resetUserPasswordResetPasswordPost(resetPasswordRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary User
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userUserGet(options?: any): AxiosPromise<User> {
            return localVarFp.userUserGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Users
         * @param {string} [pageToken] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersUsersGet(pageToken?: string, pageSize?: number, options?: any): AxiosPromise<UserCollections> {
            return localVarFp.usersUsersGet(pageToken, pageSize, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UsersApi - object-oriented interface
 * @export
 * @class UsersApi
 * @extends {BaseAPI}
 */
export class UsersApi extends BaseAPI {
    /**
     * 
     * @summary Get Users User Id
     * @param {string} userId 
     * @param {string} [pageToken] 
     * @param {number} [pageSize] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getUsersUserIdUsersUserIdGet(userId: string, pageToken?: string, pageSize?: number, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).getUsersUserIdUsersUserIdGet(userId, pageToken, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Post User
     * @param {UserCreate} userCreate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public postUserUserPost(userCreate: UserCreate, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).postUserUserPost(userCreate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Reset User Password
     * @param {ResetPasswordRequest} resetPasswordRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public resetUserPasswordResetPasswordPost(resetPasswordRequest: ResetPasswordRequest, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).resetUserPasswordResetPasswordPost(resetPasswordRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary User
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public userUserGet(options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).userUserGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Users
     * @param {string} [pageToken] 
     * @param {number} [pageSize] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersUsersGet(pageToken?: string, pageSize?: number, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersUsersGet(pageToken, pageSize, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * WeddingsApi - axios parameter creator
 * @export
 */
export const WeddingsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Delete Weddings Wedding Id
         * @param {string} weddingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteWeddingsWeddingIdWeddingsWeddingIdDelete: async (weddingId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'weddingId' is not null or undefined
            assertParamExists('deleteWeddingsWeddingIdWeddingsWeddingIdDelete', 'weddingId', weddingId)
            const localVarPath = `/weddings/{wedding_id}/`
                .replace(`{${"wedding_id"}}`, encodeURIComponent(String(weddingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication _Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "_Bearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Wedding
         * @param {string} weddingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWeddingWeddingsWeddingIdGet: async (weddingId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'weddingId' is not null or undefined
            assertParamExists('getWeddingWeddingsWeddingIdGet', 'weddingId', weddingId)
            const localVarPath = `/weddings/{wedding_id}/`
                .replace(`{${"wedding_id"}}`, encodeURIComponent(String(weddingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Weddings
         * @param {string} [pageToken] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWeddingsWeddingsGet: async (pageToken?: string, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/weddings/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication _Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "_Bearer", [], configuration)

            if (pageToken !== undefined) {
                localVarQueryParameter['page_token'] = pageToken;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Patch Weddings Wedding Id
         * @param {string} weddingId 
         * @param {WeedingPatch} weedingPatch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchWeddingsWeddingIdWeddingsWeddingIdPatch: async (weddingId: string, weedingPatch: WeedingPatch, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'weddingId' is not null or undefined
            assertParamExists('patchWeddingsWeddingIdWeddingsWeddingIdPatch', 'weddingId', weddingId)
            // verify required parameter 'weedingPatch' is not null or undefined
            assertParamExists('patchWeddingsWeddingIdWeddingsWeddingIdPatch', 'weedingPatch', weedingPatch)
            const localVarPath = `/weddings/{wedding_id}/`
                .replace(`{${"wedding_id"}}`, encodeURIComponent(String(weddingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication _Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "_Bearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(weedingPatch, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Post Weddings
         * @param {WeddingCreate} weddingCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postWeddingsWeddingsPost: async (weddingCreate: WeddingCreate, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'weddingCreate' is not null or undefined
            assertParamExists('postWeddingsWeddingsPost', 'weddingCreate', weddingCreate)
            const localVarPath = `/weddings/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication _Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "_Bearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(weddingCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WeddingsApi - functional programming interface
 * @export
 */
export const WeddingsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = WeddingsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Delete Weddings Wedding Id
         * @param {string} weddingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteWeddingsWeddingIdWeddingsWeddingIdDelete(weddingId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteWeddingsWeddingIdWeddingsWeddingIdDelete(weddingId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Wedding
         * @param {string} weddingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWeddingWeddingsWeddingIdGet(weddingId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Wedding>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWeddingWeddingsWeddingIdGet(weddingId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Weddings
         * @param {string} [pageToken] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWeddingsWeddingsGet(pageToken?: string, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WeddingCollections>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWeddingsWeddingsGet(pageToken, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Patch Weddings Wedding Id
         * @param {string} weddingId 
         * @param {WeedingPatch} weedingPatch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchWeddingsWeddingIdWeddingsWeddingIdPatch(weddingId: string, weedingPatch: WeedingPatch, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Wedding>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchWeddingsWeddingIdWeddingsWeddingIdPatch(weddingId, weedingPatch, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Post Weddings
         * @param {WeddingCreate} weddingCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postWeddingsWeddingsPost(weddingCreate: WeddingCreate, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Wedding>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postWeddingsWeddingsPost(weddingCreate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * WeddingsApi - factory interface
 * @export
 */
export const WeddingsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = WeddingsApiFp(configuration)
    return {
        /**
         * 
         * @summary Delete Weddings Wedding Id
         * @param {string} weddingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteWeddingsWeddingIdWeddingsWeddingIdDelete(weddingId: string, options?: any): AxiosPromise<any> {
            return localVarFp.deleteWeddingsWeddingIdWeddingsWeddingIdDelete(weddingId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Wedding
         * @param {string} weddingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWeddingWeddingsWeddingIdGet(weddingId: string, options?: any): AxiosPromise<Wedding> {
            return localVarFp.getWeddingWeddingsWeddingIdGet(weddingId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Weddings
         * @param {string} [pageToken] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWeddingsWeddingsGet(pageToken?: string, pageSize?: number, options?: any): AxiosPromise<WeddingCollections> {
            return localVarFp.getWeddingsWeddingsGet(pageToken, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Patch Weddings Wedding Id
         * @param {string} weddingId 
         * @param {WeedingPatch} weedingPatch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchWeddingsWeddingIdWeddingsWeddingIdPatch(weddingId: string, weedingPatch: WeedingPatch, options?: any): AxiosPromise<Wedding> {
            return localVarFp.patchWeddingsWeddingIdWeddingsWeddingIdPatch(weddingId, weedingPatch, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Post Weddings
         * @param {WeddingCreate} weddingCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postWeddingsWeddingsPost(weddingCreate: WeddingCreate, options?: any): AxiosPromise<Wedding> {
            return localVarFp.postWeddingsWeddingsPost(weddingCreate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * WeddingsApi - object-oriented interface
 * @export
 * @class WeddingsApi
 * @extends {BaseAPI}
 */
export class WeddingsApi extends BaseAPI {
    /**
     * 
     * @summary Delete Weddings Wedding Id
     * @param {string} weddingId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WeddingsApi
     */
    public deleteWeddingsWeddingIdWeddingsWeddingIdDelete(weddingId: string, options?: AxiosRequestConfig) {
        return WeddingsApiFp(this.configuration).deleteWeddingsWeddingIdWeddingsWeddingIdDelete(weddingId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Wedding
     * @param {string} weddingId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WeddingsApi
     */
    public getWeddingWeddingsWeddingIdGet(weddingId: string, options?: AxiosRequestConfig) {
        return WeddingsApiFp(this.configuration).getWeddingWeddingsWeddingIdGet(weddingId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Weddings
     * @param {string} [pageToken] 
     * @param {number} [pageSize] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WeddingsApi
     */
    public getWeddingsWeddingsGet(pageToken?: string, pageSize?: number, options?: AxiosRequestConfig) {
        return WeddingsApiFp(this.configuration).getWeddingsWeddingsGet(pageToken, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Patch Weddings Wedding Id
     * @param {string} weddingId 
     * @param {WeedingPatch} weedingPatch 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WeddingsApi
     */
    public patchWeddingsWeddingIdWeddingsWeddingIdPatch(weddingId: string, weedingPatch: WeedingPatch, options?: AxiosRequestConfig) {
        return WeddingsApiFp(this.configuration).patchWeddingsWeddingIdWeddingsWeddingIdPatch(weddingId, weedingPatch, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Post Weddings
     * @param {WeddingCreate} weddingCreate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WeddingsApi
     */
    public postWeddingsWeddingsPost(weddingCreate: WeddingCreate, options?: AxiosRequestConfig) {
        return WeddingsApiFp(this.configuration).postWeddingsWeddingsPost(weddingCreate, options).then((request) => request(this.axios, this.basePath));
    }
}


