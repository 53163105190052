import axios from "axios";

import {
  Configuration,
  GuestsApiFactory,
  RootApiFactory,
  TablesApiFactory,
  UsersApiFactory,
  WeddingsApiFactory,
} from "../api";

export const config = new Configuration({});

if (typeof window !== "undefined") {
  const access_token = window.localStorage.getItem("access_token");
  if (access_token)
    config.accessToken = access_token;
  config.basePath =
    window.localStorage.getItem("base_path") || "https://wedding.ottozhang.com";
    // window.localStorage.getItem("base_path") || "http://localhost:8000";
  console.log(config.basePath)
}

export function setApiBasePath(s: string) {
  window.localStorage.base_path = s;
  config.basePath = s;
}

const api = {
  guests: GuestsApiFactory(config),
  root: RootApiFactory(config),
  tables: TablesApiFactory(config),
  users: UsersApiFactory(config),
  weddings: WeddingsApiFactory(config),
};

axios.interceptors.response.use(
  (c) => c,
  (e) => {
    if (e?.response?.status === 401) {
      (window as unknown as any).location = "/login";
    }
  }
);

export default api;
