import React, { useState, useEffect, useCallback } from "react";
import { useParams, useNavigate } from 'react-router-dom';
import { Button } from "react-bootstrap";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

import api from '../../components/api';
import NavBar from "../../components/NavBar/NavBar";
import DraggableTableIcon, { TableType } from "../../components/DroppableTableIcon/DroppableTableIcon";
import "./WeddingTableLayoutPage.css";

function WeddingTableLayoutPage() {
  const navigate = useNavigate();
  const { weddingId } = useParams<{ weddingId: string }>();
  const [tables, setTables] = useState<TableType[]>([]);

  const [layoutWidth, setLayoutWidth] = useState<number>(600);
  const [layoutHeight, setLayoutHeight] = useState<number>(600);

  const loadTableStyle = useCallback(() => {
    if (weddingId) {
      api.weddings.getWeddingWeddingsWeddingIdGet(weddingId).then(data => {
        if (data.data.wedding_style) {
          const wedding_style = JSON.parse(data.data.wedding_style);
          setLayoutHeight(wedding_style.width);
          setLayoutHeight(wedding_style.height);
        }
      });
      api.tables.getWeddingsWeddingIdTablesWeddingsWeddingIdTablesGet(weddingId).then(data => {
        const tableTypes = data.data.contents.map(table => {
          if (table.table_style)
            return JSON.parse(table.table_style)
          else
            return {
              id: table.table_id,
              name: table.table_name,
              x: 0,
              y: 0,
              width: 80,
              height: 80,
              imageUrl: "https://www.ottozhang.com/static/img/icons/round_table.png",
            }
        })
        setTables(tableTypes);
        console.log(tableTypes)
      });
    }
  }, [weddingId]);

  useEffect(() => {
    loadTableStyle();
  }, [loadTableStyle]);

  useEffect(() => {
    const container = document.querySelector(".tables-container");
    if (container) {
      (container as HTMLElement).style.width = `${layoutWidth}px`;
      (container as HTMLElement).style.height = `${layoutHeight}px`;
    }
  }, [layoutWidth, layoutHeight]);

  const handleDragEnd = (tableId: string, x: number, y: number) => {
    setTables((prevTables) =>
      prevTables.map((table) => {
        if (table.id === tableId) {
          return { ...table, x, y };
        }
        return table;
      })
    );
  };

  const handleResize = (tableId: string, width: number, height: number) => {
    setTables((prevTables) =>
      prevTables.map((table) => {
        if (table.id === tableId) {
          return { ...table, width, height };
        }
        return table;
      })
    );
  };

  const handleSave = () => {
    tables.map(table => {
      api.tables.patchTablesTableIdTablesTableIdPatch(table.id, {
        table_style: JSON.stringify(table)
      })
      return table;
    })
    if (weddingId)
      api.weddings.patchWeddingsWeddingIdWeddingsWeddingIdPatch(weddingId, {
        wedding_style: JSON.stringify({
          width: layoutWidth,
          height: layoutHeight,
        })
      });
  };

  const handleReset = () => {
    loadTableStyle();
  };

  return (
    <div>
      <NavBar></NavBar>
      <div className="button-container">
        <Button onClick={() => navigate(`/weddings/${weddingId}/table-list`)} className="back-button">
          <i className="fa fa-angle-left"></i> Back
        </Button>
        <input
          className="size-input"
          type="number"
          value={layoutWidth}
          onChange={(e) => setLayoutWidth(Number(e.target.value))}
          placeholder="Width"
        /> x
        <input
          className="size-input"
          type="number"
          value={layoutHeight}
          onChange={(e) => setLayoutHeight(Number(e.target.value))}
          placeholder="Height"
        />
        <Button onClick={handleReset} className="reset-button">Reset</Button>
        <Button onClick={handleSave} className="save-button">Save</Button>
      </div>
      <DndProvider backend={HTML5Backend}>
        <div className="table-layout-page">
          <div className="tables-container">
            {tables.map((table) => (
              <DraggableTableIcon key={table.id} table={table} onDragEnd={handleDragEnd} onResize={handleResize} />
            ))}
          </div>
        </div>
      </DndProvider>
    </div>
  );
}

export default WeddingTableLayoutPage;
